import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import React from 'react';
import './global.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import products from './bd/products';
import categories from './bd/categories';
import { useParams } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function ProductList() {

    let { language, categoryId } = useParams();

    let subCategory = categories.map(x=> x.subCategories.find(z=> z.id === categoryId)).filter(y=> y != undefined)[0]
    const productsFilters = products.filter(x => x.categoryId === subCategory.id && x.online);

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/" className='productListLink'>
            Home
        </Link>,
        <Typography key="2" color="text.primary">
            {subCategory.categoryName}
        </Typography>,
    ];

    return (
        <>
            <div className='center spaceFooter'>
                <div className="header">
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
                <Divider />
                <Box sx={{ flexGrow: 1 }} marginTop={5}>
                    <Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 2, sm: 8, md: 16 }}>
                        {productsFilters.map((prod, index) => (
                            <Grid item xs={12} sm={3} md={4} key={index}>
                                <CardActionArea href={`${language}/products/${subCategory.id}/${prod.id}`} className="hrefColor">
                                    <Card className="list">
                                        <CardMedia
                                            component="img"
                                            alt="green iguana"
                                            image={require("./../static/images/" + prod.images[0])}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="caption" component="div" color="#727272">
                                                {prod.categoryName} - {prod.brandName}
                                            </Typography>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {prod.name}
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                Consulte-nos
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </CardActionArea>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                {products.length > 20 ?? <div className="paginaton">
                    <Pagination count={10} color="success" shape="rounded" />
                </div>}

            </div>
        </>
    )
}

export default ProductList;