import * as React from 'react'
import './global.css';
import './Product.css';
import Grid from '@mui/material/Grid';
import TabsProducts from './TabsProduct'
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Carousel } from 'react-responsive-carousel';
import './productGalery.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useParams } from "react-router-dom";
import products from './bd/products';
// import { Link } from 'react-router-dom';
import categories from './bd/categories';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function Product() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let { language, categoryId, productId } = useParams();

    // const category = categories.filter(x => x.id === categoryId);
    const prod = products.find(x => x.id === productId);

    let subCategoryName = categories.map(x=> x.subCategories.find(z=> z.id === categoryId)).filter(y=> y != undefined)[0]

    // var unit = prod.stock;
    // var classStock = "";
    // var stockInfo = "";

    // if (unit > 0) {
    //     classStock = "stockInfoGreen";
    //     stockInfo = `Disponivel ${unit} unidades`;
    // } else {
    //     classStock = "stockInfoRed";
    //     stockInfo = `Indisponivel`
    // }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href={`/${language}`} className='productListLink'>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" href={`${language}/products/${subCategoryName.id}`} className='productListLink'>
            {subCategoryName.categoryName}
        </Link>,
        <Typography key="3" color="text.primary">
            {prod.id}
        </Typography>,
    ];

    return (
        <>
            <div className='center test'>
                <div className="header">
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
                <Divider />
                <Grid container spacing={2} className="productContainer">
                    <Grid item className="productImg" xs={12} md={6} >
                        <Carousel showStatus={false} autoPlay={false}>
                            {prod.images.map(image => (
                                <div>
                                    <img src={require("./../static/images/" + image)} />
                                </div>
                            ))}
                        </Carousel>
                    </Grid>
                    <Grid item className="productInfo" xs={12} md={7}>
                        <div className="title">
                            <Typography gutterBottom variant="caption" component="div" color="#727272">
                                {prod.categoryName} - {prod.brandName}
                            </Typography>
                            <Typography gutterBottom variant="h4" component="div" className='nameProduct'>
                                {prod.name}
                            </Typography>
                            <Typography gutterBottom variant="caption" component="div" color="#727272">
                                Ref: {prod.reference}
                            </Typography>
                            {/* <br />
                            <Typography gutterBottom variant="subtitle2" component="div">
                                <CircleIcon className={classStock} /> {stockInfo}
                            </Typography> */}
                        </div>
                        <hr className="dividerProduct" />
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="specifications">
                            <Grid item xs={6}>
                                <span className="textBold">Espessura Total: </span> 7 mm
                            </Grid>
                            <Grid item xs={6}>
                                <span className="textBold">Camada de desgaste:</span> 0.5 mm
                            </Grid>
                            <Grid item xs={6}>
                                <span className="textBold">Resistência à humidade</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className="textBold">Material 100% virgem</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className="textBold">Compatível com piso radiante</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className="textBold">19db acústico</span>
                            </Grid>
                        </Grid>
                        <div>
                            <Link href={`${language}/contacts`}>
                                <button className='price' >Contacte-nos</button>
                            </Link>
                            {/* <Modal
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="keep-mounted-modal-title"
                                aria-describedby="keep-mounted-modal-description"
                                className="contactsProdInfo"
                            >
                                <Box sx={style}>
                                    <Grid item xs={12} md={12}>
                                        <h1>Contactos</h1>
                                        <hr className="dividerContacts" />
                                        <div className="contactsInfo">
                                            <Typography gutterBottom variant="h6" component="div">
                                                Aresta Perfil, Madeiras lda
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                Parque Industrial da Barca, Nº 445
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                4795-038 Santo Tirso                        </Typography>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                <span className="textBold">Tlm: </span>(+351) 917324660
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                <span className="textBold">Email: </span>arestaperfil@gmail.com
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Box>
                            </Modal> */}
                        </div>
                    </Grid>
                </Grid>
                <TabsProducts product={prod}/>
            </div>
        </>
    );
}

export default Product;