let categoriesWithSubCategories = [
    {
        id: "1",
        categoryName: "Flutuantes",
        subCategories: [
            {
                id: "1",
                categoryName: "Vinílicos SPC",
                online: true
            },
            {
                id: "2",
                categoryName: "Multicamadas",
                online: false
            }
        ],
        online: true
    },
    {
        id: "200",
        categoryName: "Painéis",
        subCategories: [
            {
                id: "3",
                categoryName: "Painéis Interior",
                online: true
            },
            {
                id: "4",
                categoryName: "Painéis Exterior",
                online: true
            }
        ],
        online: false
    },
    {
        id: "300",
        categoryName: "Decks",
        subCategories: [
            {
                id: "5",
                categoryName: "Interior",
                online: true
            },
            {
                id: "6",
                categoryName: "Exterior",
                online: true
            }
        ],
        online: false
    }
];

export default categoriesWithSubCategories;