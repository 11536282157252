import Interior from "./components/Interior";
import Exterior from "./components/Exterior";
import { Home } from "./components/Home";
import ProductList from "./components/ProductList";
import Product from "./components/Product";
import Contacts from "./components/Contacts";
import { Navigate } from 'react-router-dom';

const AppRoutes = [
  {
    index: true,
    element: <Navigate to="/pt" />
  },
  // {
  //   path: '*',
  //   element: <Navigate to="/" />
  // },
  {
    path: '/:language',
    element: <Home />
  },
  {
    path: '/:language/services/interior',
    element: <Interior />
  },
  {
    path: '/:language/services/outdoor',
    element: <Exterior />
  },
  {
    path: '/:language/products/:categoryId',
    element: <ProductList />
  },
  {
    path: '/:language/products/:categoryId/:productId',
    element: <Product />
  },
  {
    path: '/:language/contacts',
    element: <Contacts />
  }
];

export default AppRoutes;
